import React, { useState, Fragment, useEffect } from "react";
import {
  Button,
  MenuItem,
  FormControlLabel,
  TextField as MuiTextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field, Form } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";
import arrayMutators from "final-form-arrays";
import classnames from "classnames";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { volunteerActions, setToast, teamActions } from "../../state";
// import { isPerfectGameOrg } from "../../lib";
import { required, email, composeValidators } from "../../lib/validators";
import { FamilyMembers } from "./FamilyMembers";
import { volunteerRegistrationFormStyles } from "./VolunteerRegistrationForm.styles";

function _VolunteerRegistrationForm(props) {
  const {
    campaign: {
      orgId,
      campaignId,
      class_options,
      volunteer_label = "",
      hasVolunteerTexting,
      name = "",
      hide_teams,
      allow_associated_volunteer_accounts,
      company_name = "",
      company_frontend_url = "",
      require_volunteer_external_id,
      volunteer_external_id_label,
    },
    actions,
    teamActions,
    external_id,
  } = props;
  const classes = volunteerRegistrationFormStyles();
  const dispatch = useDispatch();
  const [teams, setTeams] = useState([]);
  const [showTeams, setShowTeams] = useState(false);
  // const [isPGOrg, setIsPGOrg] = useState(false);
  const [externalIdDefaultValue, setExternalIdDefaultValue] = useState("");

  useEffect(() => {
    if (external_id) setExternalIdDefaultValue(external_id);
    // setIsPGOrg(isPerfectGameOrg(orgId));
  }, [external_id /*orgId*/]);

  useEffect(() => {
    const getTeams = async () => {
      const _teams = await teamActions.getTeamAutocompleteList(campaignId);
      setTeams(_teams);
    };
    if (campaignId && !hide_teams) getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, hide_teams]);

  useEffect(() => {
    if (Array.isArray(teams) && teams.length) setShowTeams(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  const onSubmit = async values => {
    const email = values.email;
    actions.setVolunteerUserInfo({ volunteerUserEmail: email });
    const res = await actions.createVolunteer(values, orgId, campaignId);
    const { errorMessage } = res;
    if (errorMessage) {
      dispatch(setToast(errorMessage));
      return { submitFailed: true };
    }
    return { submitSucceeded: true };
  };

  return (
    <Fragment>
      <div className={classes.instructions}>
        Please fill out the form below to register for this campaign
      </div>
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        render={props => {
          const { handleSubmit, submitting, submitSucceeded, form } = props;
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.container}>
                <div className={classes.inputGroup}>
                  <Field
                    name="first_name"
                    label={
                      <span>
                        <span className={classes.capitalize}>
                          {volunteer_label}
                        </span>
                        's first name
                      </span>
                    }
                    component={TextField}
                    required
                    validate={required}
                    className={classes.halfWidthInput}
                  />
                  <Field
                    name="last_name"
                    label={
                      <span>
                        <span className={classes.capitalize}>
                          {volunteer_label}
                        </span>
                        's last name
                      </span>
                    }
                    component={TextField}
                    required
                    validate={required}
                    className={classes.halfWidthInput}
                  />
                </div>

                <div className={classes.inputGroup}>
                  <Field
                    name="email"
                    label="Email"
                    fullWidth={true}
                    component={TextField}
                    required
                    validate={composeValidators(required, email)}
                    className={classes.halfWidthInput}
                    type="email"
                  />

                  <Field
                    name="phone"
                    label="Phone"
                    required
                    validate={required}
                    component={TextField}
                    className={classes.halfWidthInput}
                    type="phone"
                  />
                </div>

                <div className={classes.inputGroup}>
                  {Array.isArray(class_options) ? (
                    <Field
                      name="class_name"
                      label="Class"
                      required
                      validate={required}
                      select
                      component={TextField}
                      className={classes.halfWidthInput}
                    >
                      {class_options.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  ) : (
                    <Field
                      name="class_name"
                      label="Class"
                      component={TextField}
                      className={classnames(
                        classes.halfWidthInput,
                        classes.input,
                      )}
                    />
                  )}

                  {showTeams && (
                    <Autocomplete
                      className={classes.halfWidthInput}
                      getOptionLabel={option => option.name}
                      options={teams}
                      getOptionSelected={(option, val) => option.id === val.id}
                      onChange={(_e, val) => {
                        form.change("team_id", val ? val.id : null);
                      }}
                      renderInput={params => (
                        <MuiTextField {...params} label="Join a Team" />
                      )}
                    />
                  )}
                </div>

                {require_volunteer_external_id && (
                  <Field
                    name="external_id"
                    label={
                      volunteer_external_id_label
                        ? volunteer_external_id_label
                        : "School-Issued Student ID"
                    }
                    defaultValue={externalIdDefaultValue}
                    fullWidth={true}
                    component={TextField}
                    required
                    validate={required}
                    className={classes.input}
                  />
                )}
                {/* {isPGOrg && (
                  <div className={classes.extIdMsgWrapper}>
                    <div>
                      Your PG PLAYER ID was included in you welcome email.
                    </div>
                    <div>If you lost your PLAYER ID please contact</div>
                    <div>
                      <a
                        className={classes.emailLink}
                        href="mailto:PGCrosschecker@perfectgame.org"
                      >
                        PGCrosschecker@perfectgame.org
                      </a>
                    </div>
                  </div>
                )} */}

                <FamilyMembers />

                {hasVolunteerTexting && (
                  <FormControlLabel
                    className={classes.input}
                    classes={{
                      root: classes.formControl,
                      label: classes.checkboxLabel,
                    }}
                    control={
                      <Field
                        name="agreed_to_receive_texts"
                        component={Checkbox}
                        color="primary"
                        type="checkbox"
                        defaultValue={true}
                        classes={{ root: classes.checkbox }}
                      />
                    }
                    label={
                      <div>
                        <div>
                          I agree to receive account related text messages from{" "}
                          {company_name}
                        </div>
                        <div className={classes.textLegal}>
                          Message and data rates may apply. Reply STOP to opt
                          out. Reply HELP for assistance. Frequency varies.
                          Subject to{" "}
                          {/* eslint-disable-next-line react/jsx-no-target-blank */}
                          <a
                            href={`${company_frontend_url}/privacy`}
                            target="_blank"
                            className={classes.privacyLink}
                          >
                            Privacy Policy
                          </a>
                          .
                        </div>
                      </div>
                    }
                  />
                )}

                {allow_associated_volunteer_accounts && (
                  <div className={classes.additionalUsersMsg}>
                    Additional users can be created in your{" "}
                    <span className={classes.strong}>Account</span> or{" "}
                    <span className={classes.strong}>Profile</span> settings,
                    with the same email address, once you have registered.
                  </div>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  type="submit"
                  disabled={submitting || submitSucceeded}
                >
                  Sign up for {name}'s campaign
                </Button>
              </div>
            </form>
          );
        }}
      />
    </Fragment>
  );
}

export const VolunteerRegistrationForm = connect(
  state => {
    return { campaign: state.campaign };
  },
  dispatch => {
    return {
      actions: bindActionCreators(volunteerActions, dispatch),
      teamActions: bindActionCreators(teamActions, dispatch),
    };
  },
)(_VolunteerRegistrationForm);
